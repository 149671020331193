import blogImage from "../assets/blogsImg/blogImage.png";
import blog1 from "../assets/blogsImg/blog1.png";
import blog2 from "../assets/blogsImg/blog2.JPG";
import blog3 from "../assets/blogsImg/blog3.jpg";
import blog4 from "../assets/blogsImg/blog4.jpg";
import blog5 from "../assets/blogsImg/blog5.jpg";

export { blogImage };

export const blogs = [
  {
    id: "0",
    slug: "about-founder-hakim-kerrache",
    title: "About the Foundation and CEO of G-Cosmos Web",
    subtitle: "Meet Hakim Kerrache: Founder and CEO of G-Cosmos Web",
    photo: blog1,
    author: "Hakim Kerrache",
    createdAt: "2020",
    location: "Ukraine",
  },
  {
    id: "1",
    slug: "our-vision-innovative-web-solutions",
    title: "Redefining the Digital Landscape with Innovative Web Solutions",
    subtitle: "Empowering Businesses in Algeria and Beyond",
    photo: blog2,
    author: "Hakim Kerrache",
    createdAt: "2023",
    location: "Bali, Indonesia",
  },
  {
    id: "2",
    slug: "future-of-web-development",
    title:
      "The Future of Web Development: Why Every Business Needs a Website or App",
    subtitle: " Staying Ahead in a Digital-First World",
    photo: blog3,
    author: "Alvin B",
    createdAt: "2023",
    location: "Kuala Lumpur",
  },
  {
    id: "3",
    slug: "top-5-reasons-your-business-needs-mobile-responsive-website",
    title: "Top 5 Reasons Your Business Needs a Mobile-Responsive Website",
    subtitle:
      " Why a Mobile-Responsive Website is Essential for Business Growth in Algeria",
    photo: blog4,
    author: "Hakim K",
    createdAt: "2023",
    location: "Oran, Algeria",
  },
  {
    id: "4",
    slug: "how-to-choose-the-right-web-development-partner-for-your-business",
    title:
      "The Future of Web Development: Why Every Business Needs a Website or App",
    subtitle:
      " Essential Tips for Selecting a Web Development Company That Understands Your Needs",
    photo: blog5,
    author: "Alvin B",
    createdAt: "2024",
    location: "Algiers, Algeria",
  },
  // more blogs here
];

export default [
  {
    slug: "about-founder-hakim-kerrache",
    title: "About the Foundation and CEO of G-Cosmos Web",
    subtitle: "Meet Hakim Kerrache: Founder and CEO of G-Cosmos Web",
    description: [
      "Hakim Kerrache, the visionary behind G-Cosmos Web, has always been fascinated by the digital world. His journey began in 2015 when he started learning programming, driven by a deep interest in technology and innovation. I was always curious about how things work on the internet, says Hakim. This curiosity led me to explore various software and programming languages, continuously pushing my boundaries.",
      "After graduating in 2019, Hakim didn't waste any time. He was determined to turn his ideas into reality, diving deep into advanced training and refining his programming skills. During this period, he worked as a freelancer, gaining invaluable experience and understanding the nuances of client needs and market demands.",
      "By 2021, with a wealth of knowledge and experience under his belt, Hakim decided it was time to take a bold step. I realized that to truly make an impact, I needed to build something of my own, he recalls. And so, G-Cosmos Web was born—a company dedicated to leveraging modern programming languages and cutting-edge technologies to deliver exceptional digital solutions.",
      "Today, G-Cosmos Web is a thriving enterprise with a team of highly skilled developers who are experts in nearly all modern programming languages. Our team is our strength, Hakim states proudly. Together, we are on a mission to revolutionize the digital landscape, one website, app, and CRM at a time.",
    ],
    photo: blog1,
    author: "Hakim Kerrache",
    createdAt: "2020",
    location: "Ukraine",
  },
  {
    slug: "our-vision-innovative-web-solutions",
    title: "Redefining the Digital Landscape with Innovative Web Solutions",
    subtitle: "Empowering Businesses in Algeria and Beyond",
    description: [
      "Empowering Businesses in Algeria and Beyond",
      "We believe that every business, regardless of its size or location, deserves access to top-tier digital tools, says Hakim Kerrache, Founder, and CEO. Our mission is to simplify web development and make these technologies available to everyone, helping businesses of all kinds grow globally.",
      "G-Cosmos Web specializes in the creation of dynamic websites, mobile apps, and robust CRM systems tailored to meet the unique needs of each client. We want to change the way people think about the web in Algeria, Hakim explains. Our approach is to blend creativity with technology, delivering solutions that are not only functional but also innovative.",
      "We are also deeply committed to contributing to the development of third-world countries through digital empowerment. By providing accessible and affordable digital solutions, we aim to bridge the digital divide and foster sustainable growth, Hakim adds. At G-Cosmos Web, we are not just building websites; we are building a better digital future.",
    ],
    photo: blog2,
    author: "Hakim Kerrache",
    createdAt: "2023",
    location: "Bali, Indonesia",
  },
  {
    slug: "future-of-web-development",
    title:
      "The Future of Web Development: Why Every Business Needs a Website or App",
    subtitle: " Staying Ahead in a Digital-First World",
    description: [
      "In today’s digital age, having a strong online presence is not just an option—it’s a necessity. At G-Cosmos Web, we understand the crucial role that websites, apps, and CRMs play in helping businesses grow and reach more customers. The future of web development is here, and it’s transforming the way companies operate, says Hakim Kerrache, CEO of G-Cosmos Web.",
      "A website or an app acts as a digital storefront for your business, open 24/7, reaching customers beyond geographical boundaries. Having a professional website not only establishes credibility but also serves as a powerful marketing tool, Hakim explains. It allows businesses to showcase their products or services, engage with customers, and build lasting relationships.",
      "For businesses looking to scale and stay competitive, investing in a comprehensive digital strategy that includes a website, app, or CRM is essential. Those who are already online are a step ahead of their competitors, Hakim notes. In just a few years, businesses with a strong online presence will be at a significant advantage.",
      "At G-Cosmos Web, we are committed to helping businesses harness the power of digital tools. We believe that by providing innovative and user-friendly digital solutions, we can help businesses not only survive but thrive in this digital-first world, Hakim concludes. Don’t get left behind—embrace the future of web development with G-Cosmos Web.",
    ],
    photo: blog3,
    author: "Alvin B",
    createdAt: "2023",
    location: "Bali, Indenesia",
  },
  {
    slug: "top-5-reasons-your-business-needs-mobile-responsive-website",
    title: "Top 5 Reasons Your Business Needs a Mobile-Responsive Website",
    subtitle:
      " Why a Mobile-Responsive Website is Essential for Business Growth in Algeria",
    description: [
      "In today's digital landscape, having a mobile-responsive website is not just a luxury—it's a necessity. In Algeria, where mobile internet usage is rapidly growing, businesses must adapt to this trend to stay competitive. In this blog, we explore the top five reasons why your business needs a mobile-responsive website and how it can enhance your customer experience, improve search engine rankings, and increase conversion rates.",
      "1 - Reach a Broader Audience: With a significant increase in mobile internet usage in Algeria, a mobile-responsive website ensures your business is accessible to all users, regardless of their device.",
      "2 - Improve User Experience: A responsive website adapts to any screen size, providing an optimal viewing experience. This reduces bounce rates and increases user engagement, which can lead to higher sales.",
      "3 - Boost Search Engine Rankings: Google prioritizes mobile-friendly websites in search results. A responsive design can improve your site's visibility on search engines, driving more organic traffic.",
      "4 - Increase Conversion Rates: A seamless browsing experience across all devices encourages users to stay longer on your site, leading to higher conversion rates.",
      "5 - Stay Ahead of Competitors: Many businesses in Algeria still don't have a mobile-responsive website. By upgrading your site, you gain a competitive edge in your industry.",
      "By understanding these benefits, you can make informed decisions about your digital presence and ensure your business thrives in a mobile-first world.",
    ],
    photo: blog4,
    author: "Alvin B",
    createdAt: "2024",
    location: "Oran, Algeria",
  },
  {
    slug: "how-to-choose-the-right-web-development-partner-for-your-business",
    title:
      "How to Choose the Right Web Development Partner for Your Business in Algeria",
    subtitle:
      " Essential Tips for Selecting a Web Development Company That Understands Your Needs",
    description: [
      "In today’s digital age, having a strong online presence is not just an option—it’s a necessity. At G-Cosmos Web, we understand the crucial role that websites, apps, and CRMs play in helping businesses grow and reach more customers. The future of web development is here, and it’s transforming the way companies operate, says Hakim Kerrache, CEO of G-Cosmos Web.",
      "A website or an app acts as a digital storefront for your business, open 24/7, reaching customers beyond geographical boundaries. Having a professional website not only establishes credibility but also serves as a powerful marketing tool, Hakim explains. It allows businesses to showcase their products or services, engage with customers, and build lasting relationships.",
      "For businesses looking to scale and stay competitive, investing in a comprehensive digital strategy that includes a website, app, or CRM is essential. Those who are already online are a step ahead of their competitors, Hakim notes. In just a few years, businesses with a strong online presence will be at a significant advantage.",
      "At G-Cosmos Web, we are committed to helping businesses harness the power of digital tools. We believe that by providing innovative and user-friendly digital solutions, we can help businesses not only survive but thrive in this digital-first world, Hakim concludes. Don’t get left behind—embrace the future of web development with G-Cosmos Web.",
    ],
    photo: blog5,
    author: "Alvin B",
    createdAt: "2024",
    location: "Algiers, Algeria",
  },
  // more blogs here
];
