import React, { useContext } from "react";

import Header from "../components/Header";
import Hero from "../components/Hero";

import Benefits from "../components/Benefits";
import Collaboration from "../components/Collaboration";
import Services from "../components/Services";
import Pricing from "../components/Pricing";
import Roadmap from "../components/Roadmap";
import Footer from "../components/Footer";

import ButtonGradient from "../assets/svg/ButtonGradient";
import HomeProducts from "../components/HomeProducts";
import ScrollToTop from "../components/ScrollToTop";
import Layout from "../components/Layout/Layout";

const HomePage = () => {
  return (
    <Layout
      title="G-Cosmos Web: Innovative Web Solutions | Creation Website, App, CRM"
      description="Modern Websites, Apps, CRMs, Built with Cutting-Edge Technology. Nous utilisons les dernières technologies pour offrir des solutions de conception Web uniques adaptées aux besoins de votre entreprise."
      keywords="G-Cosmos Web, modern web design, responsive websites, web development company, développement de sites Web Algérie, conception de sites modernes, sites Web réactifs, entreprise de développement Web, solutions numériques innovantes, sites Web d'entreprise, présence en ligne Algérie."
      author="Hakim Kerrache"
    >
      <div className="pt-[4.75rem] lg:pt-[5.25rem] overflow-hidden">
        <Header />
        <Hero />
        <Benefits />
        <HomeProducts />
        <Collaboration />
        <Services />
        <Pricing />
        <Roadmap />
        <Footer />
        <ScrollToTop />
        <ButtonGradient />
      </div>
    </Layout>
  );
};

export default HomePage;
