import {
  chromecast,
  disc02,
  github,
  facebook,
  figma,
  file02,
  homeSmile,
  instagram,
  notification2,
  notification3,
  notification4,
  plusSquare,
  recording01,
  recording03,
  roadmap1,
  roadmap2,
  roadmap3,
  roadmap4,
  searchMd,
  sliders04,
  telegram,
  whatsapp,
  logo1,
  logo2,
  logo3,
  logo4,
  logo5,
  serv1,
  serv2,
  serv3,
  serv4,
  serv5,
  serv6,
  html,
  css,
  javascript,
  typescript,
  reactjs,
  redux,
  tailwind,
  nodejs,
  mongodb,
  threejs,
  git,
  mongodb2,
  firebase,
  cyclik,
  render,
  netlify,
  clerk,
  vercel,
  stripe,
} from "../assets";
import Bimg1 from "../assets/benefits/card-1.svg";
import Bimg2 from "../assets/benefits/card-2.svg";
import Bimg3 from "../assets/benefits/card-3.svg";
import Bimg4 from "../assets/benefits/card-4.svg";
import Bimg5 from "../assets/benefits/card-5.svg";
import Bimg6 from "../assets/benefits/card-6.svg";

export const navigation = [
  {
    id: "0",
    title: "Products",
    url: "/categories",
  },
  {
    id: "1",
    title: "Pricing",
    url: "/pricing",
  },
  {
    id: "2",
    title: "Blogs",
    url: "/blogs",
  },
  {
    id: "3",
    title: "Contact",
    url: "/contact",
  },
  {
    id: "4",
    title: "About",
    url: "/about",
  },
];

export const heroIcons = [homeSmile, file02, searchMd, plusSquare];

export const notificationImages = [notification4, notification3, notification2];

export const companyLogos = [logo1, logo2, logo3, logo4, logo5];

export const gcServices = [
  "Enhanced User Experience",
  "Efficient Automation",
  "Data-Driven Insights",
  "Improved Security",
  "Scalability and Adaptability",
];

export const gcServicesIcons = [
  recording03,
  recording01,
  disc02,
  chromecast,
  sliders04,
];

export const roadmap = [
  {
    id: "0",
    title: "Revolutionizing Website Design",
    text: "G-Cosmos is pioneering innovative web design techniques, creating visually stunning and highly functional websites.",
    date: "2023",
    status: "progress",
    imageUrl: roadmap1,
    colorful: true,
  },
  {
    id: "1",
    title: "Elevating Mobile App Development",
    text: "We're pushing the boundaries of mobile app development, crafting sleek and user-friendly applications for various platforms.",
    date: "2022",
    status: "progress",
    imageUrl: roadmap2,
  },
  {
    id: "2",
    title: "Innovating CRM Solutions",
    text: "G-Cosmos is committed to developing cutting-edge CRM solutions, tailored to streamline business operations and enhance customer relationships.",
    date: "2021",
    status: "done",
    imageUrl: roadmap3,
  },
  {
    id: "3",
    title: "Optimizing User Experience Across Platforms",
    text: "Our focus is on enhancing user experiences across web and mobile platforms, ensuring seamless navigation and engagement.",
    date: "2020",
    status: "progress",
    imageUrl: roadmap4,
  },
];

export const collabText =
  "With intelligent development and top-notch security, it's the ideal solution for your business looking to grow and scale.";

export const collabContent = [
  {
    id: "0",
    title: "Seamless Integration",
    text: "The latest technology makes your app work smoother and faster",
  },
  {
    id: "1",
    title: "Smart Solutions",
    text: "We find the best solutions for your business development",
  },
  {
    id: "2",
    title: "Top-notch Security",
    text: "Your privacy and security are our first priority",
  },
];

export const collabApps = [
  {
    id: "0",
    title: "MongoDB",
    icon: mongodb2,
  },
  {
    id: "1",
    title: "Firebase",
    icon: firebase,
  },
  {
    id: "2",
    title: "Vercel",
    icon: vercel,
  },
  {
    id: "3",
    title: "Netlify",
    icon: netlify,
  },
  {
    id: "4",
    title: "Render",
    icon: render,
  },
  {
    id: "5",
    title: "Cyclik",
    icon: cyclik,
  },
  {
    id: "6",
    title: "Clerk",
    icon: clerk,
  },
  {
    id: "7",
    title: "Stripe",
    icon: stripe,
  },
];

export const pricing = [
  {
    id: "0",
    title: "Basic",
    description:
      "Perfect for a one-page website, presenting CVs, portfolios and final studies projects, etc. ",
    price: "89",
    DAprice: "19900",
    features: [
      "Logo",
      " Domain Name",
      " Business Hosting Plan",
      " SSL Certificat ",
      " One Page Website",
      " Professionnal Email",
    ],
  },
  {
    id: "1",
    title: "Premium",
    description:
      "For small businesses, we offer multi-page websites, with upgrade options for additional features.",
    price: "189",
    DAprice: "42300",
    features: [
      "Logo, Domain Name",
      " Business Hosting Plan",
      " SSL Certificat ",
      "  5-7 Page Website",
      " Professionnal Email",
      " Online sales and payment (card, Paypal, Stripe)",
      " One year free update",
      " Search engine optimization (SEO).",
    ],
  },
  {
    id: "2",
    title: "Enterprise",
    description:
      "High-end, are ideal for large businesses, CRM systems, international sites, and mobile applications (VTC). etc. include:",
    price: null,
    features: [
      "Logo, Domain Name",
      " Business Hosting Plan",
      " SSL Certificat ",
      " Unlimited number of pages",
      " Professionnal Email",
      " Online sales and payment (card, Paypal, Stripe)",
      " One year free update",
      " Search engine optimization (SEO).",
      "Apps deployment on Apple store and Google Play",
      " Youtube channel",
    ],
  },
];

export const benefits = [
  {
    id: "0",
    title: "Awesome UI/ UX design",
    text: "Elevate user experiences with stunning, intuitive designs tailored to your brand and audience.",
    backgroundUrl: Bimg1,
    iconUrl: serv1,
    imageUrl: serv1,
    url: "https://wa.link/0j7usq",
  },
  {
    id: "1",
    title: "Free Consultation 24/7",
    text: "Access expert advice and support round the clock, ensuring seamless communication and project guidance.",
    backgroundUrl: Bimg2,
    iconUrl: serv2,
    imageUrl: serv2,
    light: true,
  },
  {
    id: "2",
    title: "Safety, Perfection and Work on time",
    text: " Rest assured with our commitment to secure, flawless, and punctual delivery of every project.",
    backgroundUrl: Bimg3,
    iconUrl: serv3,
    imageUrl: serv3,
  },
  {
    id: "3",
    title: "Gifts, promotions and a year of free updates",
    text: "Enjoy exclusive perks including special offers, promotions, and complimentary updates for a year.",
    backgroundUrl: Bimg4,
    iconUrl: serv4,
    imageUrl: serv4,
    light: true,
  },
  {
    id: "4",
    title: "Easy Payment Offers",
    text: "Simplify transactions with flexible payment options, making it convenient to kickstart your project hassle-free.",
    backgroundUrl: Bimg5,
    iconUrl: serv5,
    imageUrl: serv5,
  },
  {
    id: "5",
    title: "Improve everyday",
    text: "Stay ahead with our dedication to ongoing enhancement, ensuring your digital presence evolves with industry trends.",
    backgroundUrl: Bimg6,
    iconUrl: serv6,
    imageUrl: serv6,
  },
];

export const technologies = [
  {
    name: "HTML 5",
    icon: html,
  },
  {
    name: "CSS 3",
    icon: css,
  },
  {
    name: "JavaScript",
    icon: javascript,
  },
  {
    name: "TypeScript",
    icon: typescript,
  },
  {
    name: "React JS",
    icon: reactjs,
  },
  {
    name: "Redux Toolkit",
    icon: redux,
  },
  {
    name: "Tailwind CSS",
    icon: tailwind,
  },
  {
    name: "Node JS",
    icon: nodejs,
  },
  {
    name: "MongoDB",
    icon: mongodb,
  },
  {
    name: "Three JS",
    icon: threejs,
  },
  {
    name: "git",
    icon: git,
  },
  {
    name: "figma",
    icon: figma,
  },
];

export const socials = [
  {
    id: "0",
    title: "Whatsapp",
    iconUrl: whatsapp,
    url: "https://wa.link/0j7usq",
  },
  {
    id: "1",
    title: "Instagram",
    iconUrl: instagram,
    url: "https://www.instagram.com/akim.kerrache",
  },
  {
    id: "2",
    title: "Telegram",
    iconUrl: telegram,
    url: "https://t.me/akimkerrache",
  },
  {
    id: "3",
    title: "Facebook",
    iconUrl: facebook,
    url: "https://www.facebook.com/Akim.kerrache.1?mibextid=LQQJ4d",
  },
  {
    id: "4",
    title: "Discord",
    iconUrl: github,
    url: "https://github.com/Akimkerrache",
  },
];
