import { Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Pricing from "./pages/PricingPage";
import About from "./pages/About";
import Contact from "./pages/Contact";
import BlogPage from "./pages/BlogPage";
import BlogPost from "./pages/BlogPost";
import Policy from "./pages/Policy";
import Pagenotfound from "./pages/Pagenotfound";
import Register from "./pages/Auth/Register";
import Login from "./pages/Auth/Login";

import ForgotPasssword from "./pages/Auth/ForgotPasssword";
import AdminRoute from "./components/Routes/AdminRoute";
import ClientRoute from "./components/Routes/ClientRoute";
import BusinessRoute from "./components/Routes/BusinessRoute";

import AdminDashboard from "./pages/Admin/AdminDashboard";
import CreateCategory from "./pages/Admin/CreateCategory";
import CreateProduct from "./pages/Admin/CreateProduct";
import Users from "./pages/Admin/Users";

import Products from "./pages/Admin/Products";
import UpdateProduct from "./pages/Admin/UpdateProduct";
import Search from "./pages/Search";
import ProductDetails from "./pages/ProductDetails";
import Categories from "./pages/Categories";
import CategoryProduct from "./pages/CategoryProduct";
import CartPage from "./pages/CartPage";

import AdminOrders from "./pages/Admin/AdminOrders";
import UserProfile from "./pages/Admin/UserProfile.js";

import AdminWithDrawal from "./pages/Admin/WithDrawal";
import ClientDashboard from "./pages/Client/ClientDashboard";

import CRequests from "./pages/Client/Requests";
import ClientOrders from "./pages/Client/ClientOrders";

import BusinessDashboard from "./pages/Business/BusinessDashboard";
import BusinessOrders from "./pages/Business/BusinessOrders";
import BRequests from "./pages/Business/Requests";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/product/:slug" element={<ProductDetails />} />
        <Route path="/categories" element={<Categories />} />
        <Route path="/cart" element={<CartPage />} />
        <Route path="/category/:slug" element={<CategoryProduct />} />
        <Route path="/search" element={<Search />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPasssword />} />
        <Route path="/login" element={<Login />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/about" element={<About />} />
        <Route path="/blogs" element={<BlogPage />} />
        <Route path="/blogs/:slug" element={<BlogPost />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="*" element={<Pagenotfound />} />

        <Route path="/dashboard" element={<AdminRoute />}>
          <Route path="admin" element={<AdminDashboard />} />
          <Route path="admin/create-category" element={<CreateCategory />} />
          <Route path="admin/create-product" element={<CreateProduct />} />
          <Route path="admin/product/:slug" element={<UpdateProduct />} />
          <Route path="admin/products" element={<Products />} />
          <Route path="admin/users" element={<Users />} />
          <Route path="admin/userProfile/:id" element={<UserProfile />} />
          <Route path="admin/orders" element={<AdminOrders />} />
          <Route path="admin/withdrawel" element={<AdminWithDrawal />} />
        </Route>
        <Route path="/dashboard" element={<ClientRoute />}>
          <Route path="client" element={<ClientDashboard />} />
          <Route path="client/orders" element={<ClientOrders />} />

          <Route path="client/requests" element={<CRequests />} />
        </Route>
        <Route path="/dashboard" element={<BusinessRoute />}>
          <Route path="business" element={<BusinessDashboard />} />
          <Route path="business/orders" element={<BusinessOrders />} />

          <Route path="business/requests" element={<BRequests />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
