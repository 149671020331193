import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { FaShareAlt } from "react-icons/fa";
import { TiArrowBack } from "react-icons/ti";
import Button from "../components/Button";
import Heading from "../components/Heading";
import Layout from "../components/Layout/Layout";
import Section from "../components/Section";
import BlogData from "../constants/blogs";

const BlogPost = () => {
  const navigate = useNavigate();
  const { slug } = useParams();
  const blog = BlogData.find((blog) => blog.slug === slug);

  if (!blog) {
    return <div>Blog not found</div>;
  }

  const handleBack = () => {
    navigate(`/blogs`);
  };

  // Handle share button click
  const handleShare = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      toast.success("Link copied successfully");
    } catch (error) {
      toast.error("Failed to copy the link");
    }
  };

  // Destructure the first paragraph and the rest of the paragraphs
  const [firstParagraph, ...restParagraphs] = blog.description;

  return (
    <Layout
      title={blog.title}
      description={blog.subtitle}
      keywords="G-Cosmos Web, website development, web design, digital solutions, modern websites, responsive design, web development company, innovative web solutions, business websites, online presence."
    >
      <Section crosses>
        <div className="container pro-nav-bar max-lg:mt-[80px]">
          <Button className="text-[22px] p-3" onClick={handleBack}>
            <TiArrowBack />
          </Button>
          <Button white className="text-[20px] p-3" onClick={handleShare}>
            <FaShareAlt />
          </Button>
        </div>
        <div className="container relative z-2 pt-[80px] p-5">
          <Heading tag="G-COSMOS WEB Blogs" title={blog.title} />
          <h2 className="text-center mb-5 text-n-3 mt-[-30px]">
            {blog.subtitle}
          </h2>
          <div className="blog-sec1 ">
            <div className="blog-img-div">
              <img
                src={blog.photo}
                alt="blog-photo"
                className="blog-page-img "
              />
            </div>
            <div className="pages-text-div">
              {/* Display the first paragraph */}
              <p className="body-2 sm:m-[3rem] text-n-3 contact-text-bg ">
                {firstParagraph}
              </p>
            </div>
          </div>
          <div className="block">
            {/* Display the rest of the paragraphs */}
            {restParagraphs.map((paragraph, index) => (
              <p key={index} className="body-2 mb-4 text-n-3">
                {paragraph}
              </p>
            ))}
          </div>
        </div>
      </Section>
    </Layout>
  );
};

export default BlogPost;
