import React, { useState, useEffect } from "react";
import AdminMenu from "../../components/Layout/AdminMenu";
import Layout from "./../../components/Layout/Layout";

import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import axiosInstance from "../../utils/axiosConfig";

const Products = () => {
  const [products, setProducts] = useState([]);

  // Function to convert ArrayBuffer to base64 string
  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  // Fetch all products
  const getAllProducts = async () => {
    try {
      const { data } = await axiosInstance.get("/product/get-product");
      setProducts(data.products);
      console.log(data.products);
    } catch (error) {
      console.log(error);
      toast.error("Something Went Wrong");
    }
  };

  // Lifecycle method to fetch products on component mount
  useEffect(() => {
    getAllProducts();
  }, []);

  return (
    <Layout>
      <div className="main-container">
        <div className="col-md-3">
          <AdminMenu />
        </div>
        <div className="table-container ">
          <h1 className="text-center">Liste de tous les Produits</h1>
          <table className="table">
            <thead>
              <tr>
                <th>Image</th>
                <th>Name</th>
                <th>Description</th>
                <th>Price</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {products.map((p) => (
                <tr key={p._id}>
                  <td>
                    <img
                      src={`data:${
                        p.photo.contentType
                      };base64,${arrayBufferToBase64(p.photo.data.data)}`}
                      width="50px"
                      height={"auto"}
                      alt={p.name}
                    />
                  </td>
                  <td>{p.name}</td>

                  <td>
                    <div className="max-h-[200px] overflow-auto p-2">
                      {p.description}
                    </div>
                  </td>

                  <td>{p.price}</td>
                  <td>
                    <Link
                      key={p._id}
                      to={`/dashboard/admin/product/${p.slug}`}
                      className="product-link"
                    >
                      <Button className="btn">Voir</Button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Layout>
  );
};

export default Products;
