import React from "react";
import Layout from "./../components/Layout/Layout";
import Footer from "../components/Footer";
import ButtonGradient from "../assets/svg/ButtonGradient";
import { StarsCanvas } from "../components/canvas";
import Heading from "../components/Heading";
import { about } from "../assets/index";
import Section from "../components/Section";
import Button from "../components/Button";

const About = () => {
  return (
    <Layout
      title="About Us - G-Cosmos Web"
      description="Discover G-Cosmos Web, a premier website development company specializing in modern, responsive web design and cutting-edge digital solutions. Transform your business online with our expert team"
      Keywords="G-Cosmos Web, website development, web design, digital solutions, modern websites, responsive design, web development company, innovative web solutions, business websites, online presence."
    >
      <Section crosses>
        <div className="container relative z-2 pt-[100px]">
          <Heading tag="Overview about G-COSMOS WEB" title="About Us" />

          <div className="row contactus ">
            <div className="pages-img-div lg:mt-[-170px]">
              <img src={about} alt="contactus" style={{ width: "100%" }} />
            </div>
            <div className="pages-text-div">
              <p className="body-2 mb-[3rem] text-n-3 contact-text-bg ">
                We specialize in web development, website creation and custom
                applications to help businesses grow and increase their revenue.
                We work closely with our clients to understand their specific
                needs and create Websites and Apps tailored to their brand image
                and business objectives. At G-COSMOS WEB, we believe that every
                business deserves a professional and effective online presence
                to reach its full potential.
              </p>
            </div>
          </div>
        </div>
        <StarsCanvas />
      </Section>
      <Section crosses>
        <div className="container flex">
          <div className="block align-middle">
            <Heading
              title="Our Mission and Values"
              tag="⚛️ React, ✨ JavaScript,  🟢 Node.JS, ➡️ NextJS,  🌀 TailwindCSS,  🌐 HTML,  🐘 PHP, and more!"
            />
            <h3 className="body-2 mb-24 text-n-3 mt-[-50px]">
              At G-Cosmos Web, our mission is simple: to empower businesses with
              top-notch digital solutions that enhance their online presence and
              drive success. We are committed to delivering exceptional quality
              and innovative designs that set our clients apart from the
              competition. Our core values of integrity, creativity, and
              excellence guide everything we do, ensuring that we provide not
              only outstanding websites but also a seamless and satisfying
              experience from start to finish. We believe in building
              long-lasting relationships with our clients based on trust,
              collaboration, and a shared passion for digital innovation.
            </h3>

            <Heading title="Coding Technologies" />
            <h3 className="text-center mt-[-20px]">
              Fashion digital elegance with the art of code, to create unique
              sites that tell your story online with grace and fluidity.
            </h3>
            <p className="body-2 mb-2 text-n-3">
              ✅ Embark on a digital journey of limitless possibilities with
              G-COSMOS WEB, where cutting-edge technologies converge to craft
              unparalleled online experiences.
            </p>
            <p className="body-2 mb-2 text-n-3">
              ✅ We seamlessly blend the artistic finesse of HTML, CSS, and
              JavaScript to sculpt visually captivating websites, while React
              elevates interactivity to new heights with reusable components and
              real-time responsiveness.
            </p>
            <p className="body-2 mb-2 text-n-3">
              ✅ Harnessing the server-side prowess of Node.js ensures
              lightning-fast performance, and Tailwind CSS streamlines the
              design process, making elegance effortlessly achievable.
            </p>
            <p className="body-2 mb-2 text-n-3">
              ✅ Elevate your mobile presence with Flutter, enabling stunning
              cross-platform apps with a single codebase. At G-COSMOS WEB, this
              amalgamation of technologies isn't just a toolbox; it's a gateway
              to innovation, speed, and aesthetic brilliance.
            </p>
            <p className="body-2 mb-2 text-n-3">
              ✅ Let us weave your digital dreams into reality, creating
              websites and apps that not only meet but exceed your expectations.
              Welcome to the future of web and app development – where your
              vision meets our technological prowess!
            </p>
            <hr />
            <Heading
              title="Wordpress"
              tag="SiteWeb:🛒 E-commerce, 🚚 Livraison, 🎓 E-Learning, 📄 CVs, 📁 Portfolio, and more!"
              className="mt-5"
            />
            <h3 className="text-center mt-[-20px]">
              Give a new dimension to your online presence with WordPress, the
              platform that gives you absolute control over your website. From
              engaging content to refined personalization, every pixel exudes
              creativity and professionalism.
            </h3>
            <p className="body-2 mb-2 text-n-3">
              ✅ A WordPress site offers a range of attractive benefits from a
              website building company's perspective.
            </p>
            <p className="body-2 mb-2 text-n-3">
              ✅ With its user-friendly interface, content creation and
              management becomes easy, reducing the need for in-depth technical
              expertise.
            </p>
            <p className="body-2 mb-2 text-n-3">
              ✅ In addition, the profitability of WordPress allows development
              costs to be minimized, thus offering an economically advantageous
              solution.
            </p>
            <p className="body-2 mb-2 text-n-3">
              ✅ The flexibility of the platform also allows HTML, CSS and
              JavaScript to be easily integrated, giving the possibility of
              customizing the design and functionality of the sites according to
              the specific needs of each client.
            </p>

            <hr />
            <Heading
              title="We help grow your Business."
              tag="Startup, Small and Medium Businesses, 📈 Entreprice"
              className="mt-5"
            />
            <h3 className="text-center mt-[-20px]">
              We support you in the development of your business, combining our
              expertise with your vision. Together, we build solid foundations
              for growth, transforming your ideas into thriving realities.
            </h3>
            <p className="body-2 mb-2 text-n-3">
              ✅ Online Presence: Having a website means that visitors or
              customers can always find you anytime, anywhere. Even outside
              opening hours.
            </p>
            <p className="body-2 mb-2 text-n-3">
              ✅ Marketing Strategy: We provide the best strategies for your
              type of business to achieve the best results quickly.
            </p>
            <p className="body-2 mb-2 text-n-3">
              ✅ Promote sales and services: Your website is primarily an
              advertising platform for your business, and other sponsored
              businesses as well.
            </p>
            <div className=" align-middle mt-5">
              <Button white className="self-center ml-[30%]" href="/contact">
                Contact Us
              </Button>
            </div>
          </div>
        </div>
      </Section>
      <Footer />
      <ButtonGradient />
    </Layout>
  );
};

export default About;
