import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Layout from "../components/Layout/Layout";
import Footer from "../components/Footer";
import ButtonGradient from "../assets/svg/ButtonGradient";
import { StarsCanvas } from "../components/canvas";
import Heading from "../components/Heading";
import { blogImage } from "../constants/blogs";
import { blogs } from "../constants/blogs";
import Section from "../components/Section";
import Button from "../components/Button";
import { motion } from "framer-motion";
import { FaLocationDot } from "react-icons/fa6";

const BlogPage = () => {
  const navigate = useNavigate(); // Initialize useNavigate

  const NavigateToBlog = (slug) => {
    // Logic to navigate to the blog using the slug
    navigate(`/blogs/${slug}`);
  };

  return (
    <Layout
      title="Blogs - G-Cosmos Web"
      description="Discover G-Cosmos Web, a premier website development company specializing in modern, responsive web design and cutting-edge digital solutions. Transform your business online with our expert team."
      keywords="G-Cosmos Web, website development, web design, digital solutions, modern websites, responsive design, web development company, innovative web solutions, business websites, online presence."
    >
      <Section crosses>
        <div className="container relative z-2 pt-[10px] max-sm:mt-20 ">
          <Heading tag="G-COSMOS WEB Blogs" title="Blogs" />

          <div className="row contactus">
            <div className="pages-img-div lg:mt-[-170px]">
              <img src={blogImage} alt="Blogs" style={{ width: "100%" }} />
            </div>
            <div className="pages-text-div">
              <p className="body-2 mb-[3rem] text-n-3 contact-text-bg">
                Welcome to our blog section! Explore insightful articles and
                updates on the latest trends in web development, digital
                solutions, and how we help businesses grow online.
              </p>
            </div>
          </div>
        </div>
        <StarsCanvas />
      </Section>

      <Section crosses>
        <div className="container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 mb-24">
          {blogs.map((blog) => (
            <motion.div
              key={blog.id}
              className="blog-card"
              whileHover={{ scale: 1.05 }}
              onClick={() => NavigateToBlog(blog.slug)} // Pass the slug to NavigateToBlog
            >
              <img
                src={blog.photo}
                alt={blog.title}
                className="w-full h-48 object-cover"
              />
              <div className="blog-card-content">
                <h3 className="text-xl font-semibold mb-2">{blog.title}</h3>
                <p className="text-sm text-[#d26bdb] mb-4">{blog.subtitle}</p>
                <div className="blog-card-footer">
                  <span className="text-[#00fb04] font-bold">
                    {blog.author}
                  </span>
                  <span>{blog.createdAt}</span>
                </div>
                <div className="blog-card-footer mt-3 text-[#07afde]">
                  <span>{blog.location}</span>
                  <span>
                    <FaLocationDot />
                  </span>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
        <div className="mt-8 flex justify-center">
          <Button white className="self-center" href="/contact">
            Contact Us
          </Button>
        </div>
      </Section>

      <Footer />
      <ButtonGradient />
    </Layout>
  );
};

export default BlogPage;
